import React, {useCallback, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {CSSTransition} from 'react-transition-group'
import {
  Button,
  ButtonBack,
  InputTextFormik,
  SelectFormik,
} from '../../../../../../../_gori/partials/widgets'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {useFormik} from 'formik'
import {OrdersConfig, OrderType} from '../../../../../orders/core/_const'
import {StoresListing} from '../StoresListing'
import {SettingsConfig} from '../../../../core/_const'
import SettingsService from '../../../../core/_requests'
import {toast} from 'react-toastify'
import {FromOrToModal} from '../../../../../orders'
import {omit} from 'lodash'

const ConnectTemu: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const [isReload, setIsReload] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [showModal, setShowModal] = useState<{shippingAddress: boolean}>({
    shippingAddress: false,
  })

  const {temuYup, stringYup, orderYup, regexYup, infoYup} = UseYupValidate()
  const validationSchema = Yup.object().shape({
    seller_type: temuYup.sellerType,
    store_name: temuYup.storeName,
    access_token: temuYup.accessToken,
    region: temuYup.region,
  })

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] = false
            break
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            break
          default:
            initValue.from[`from_${value.value}`] = ''
            break
        }
        return initValue
      },
      {from: {}}
    )
    return {
      ...init,
      store_type: OrderType.TEMU,
      seller_type: '',
      store_name: '',
      access_token: '',
      region: {
        url: '',
        code: '',
      },
    }
  }, [])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      let region_code = formik.values.region?.code?.length > 0 ? formik.values.region.code : 'us'
      let payload = omit({...formik.values, region_code}, 'region')

      const res = await SettingsService.connect(payload)
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTION_SUCCESSFUL'}))
        formik.resetForm()
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const goBack = () => {
    navigate('/settings/connections')
  }

  useEffect(() => {
    formik.validateForm()

    if (formik.isValid && !formik.isSubmitting) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [formik.isValid, formik.isSubmitting])

  const validateAddress = useCallback(
    (name) => {
      return {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country(),
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  return (
    <>
      {showModal.shippingAddress && (
        <FromOrToModal
          data={{
            show: showModal.shippingAddress,
            name: 'from',
            data: formik.getFieldProps('from').value,
            validate: validateAddress('from'),
          }}
          labelModal={
            intl.formatMessage({id: 'TEMU'}) + ' ' + intl.formatMessage({id: 'SHIP_FROM_LOCATION'})
          }
          handleSave={(name, data) => {
            formik.setValues({...formik.values, ...data})
            formik.submitForm()
            setShowModal((prev) => ({...prev, shippingAddress: false}))
          }}
          handleClose={() => {
            setShowModal((prev) => ({...prev, shippingAddress: false}))
          }}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className={'card p-10'}>
          <div className='card-title'>
            <div className={'d-flex gap-4 align-items-center'}>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={goBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} {intl.formatMessage({id: 'TEMU'})}
              </span>
            </div>
          </div>
          <div className='card-body d-flex justify-content-center'>
            <div className='col-md-8'>
              <SelectFormik
                className='col-12 col-sm-12'
                labelClassName='col-4 col-sm-3 col-form-label'
                label={'Temu ' + intl.formatMessage({id: 'SELLER_TYPE'})}
                required
                options={SettingsConfig.TEMU.TYPE}
                formik={formik}
                name={'seller_type'}
              />
              <InputTextFormik
                labelClassName='col-md-4 col-form-label mt-5'
                required
                label={'Temu ' + intl.formatMessage({id: 'STORE_NAME'})}
                formik={formik}
                name={'store_name'}
              />
              <InputTextFormik
                labelClassName='col-md-4 col-form-label mt-5'
                required
                label={'Temu ' + intl.formatMessage({id: 'ACCESS_TOKEN'})}
                formik={formik}
                name={'access_token'}
              />
              <SelectFormik
                className='col-12 col-sm-12'
                labelClassName='col-4 col-sm-3 col-form-label'
                label={'Temu ' + intl.formatMessage({id: 'REGION'})}
                required
                options={SettingsConfig.TEMU.REGION}
                formik={formik}
                name={'region'}
              />
              <div className='text-end'>
                <Button
                  className='btn btn-primary mt-7'
                  label={intl.formatMessage({id: 'CONNECT'})}
                  loadingText={intl.formatMessage({id: 'CONNECT'})}
                  loading={formik.isSubmitting}
                  disabled={isDisabled}
                  event={() => {
                    setShowModal((prev) => ({...prev, shippingAddress: true}))
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <StoresListing isReload={isReload} setIsReload={setIsReload} storeType={OrderType.TEMU} />
    </>
  )
}

export {ConnectTemu}
