import {OPTION_COUNTRIES} from '../../../../_gori/constants'

export const SettingsConfig = {
  TEMU: {
    APPLICATION_ID: process.env.REACT_APP_AMAZON_APPLICATION_ID,
    TYPE: [
      {
        label: 'LOCAL_SELLER',
        value: 'local_seller',
      },
    ],
    REGION: [
      {
        label: 'US_TEMU_PARTNER_PLATFORM',
        value: {url: 'https://partner.temu.com', code: 'us'},
      },
      {
        label: 'EU_TEMU_PARTNER_PLATFORM',
        value: {url: 'https://partner-eu.temu.com', code: 'eu'},
      },
    ],
  },
  // shipping
  PAGE_SIZE_ADDRESS: 200,
  OPTIONS_ORDER: [
    {label: 'BAE_ID', value: 'order_code'},
    {label: 'REFERENCE_1', value: 'reference1'},
    {label: 'REFERENCE_2', value: 'reference2'},
  ],
  OPTIONS_BARCODE: [
    {label: 'REFERENCE_1', value: 'reference1'},
    {label: 'REFERENCE_2', value: 'reference2'},
    {label: 'ITEM_SKU', value: 'sku'},
    {label: 'BAE_ID', value: 'order_code'},
  ],
  OPTIONS_LABEL_TYPE: [
    {label: 'PDF', value: 'pdf'},
    {label: 'PNG', value: 'png'},
    // {label: 'ZPL', value: 'zpl'},
  ],
  OPTIONS_LABEL_SIZE: [
    {label: '4x6', value: '4x6'},
    {label: '8.5x11', value: '8.5x11'},
    // {label: 'PNG', value: 'png'},
    // {label: 'ZPL', value: 'zpl'},
  ],
  LABELS_SLIPS: {
    LABELS: {
      TYPE: {label: 'TYPE', value: 'label_type'},
      SIZE: {label: 'PACKING_SLIP_SIZE', value: 'packing_slip_size'},
      INCLUDE_SLIPS: {
        label: 'INCLUDE_PACKING_SLIPS_WHEN_PRINTING_LABELS',
        value: 'include_slips',
      },
    },
    SLIPS: {
      HEADER: {label: 'HEADER', value: 'header'},
      ORDER_NUMBER: {label: 'ORDER', value: 'order'},
      // ORDER_NOTE: {label: 'ADD_ORDER_NOTE', value: 'order_note'},
      ORDER_DATE: {label: 'ORDER_DATE', value: 'order_date'},
      SHIP_TO: {label: 'SHIP_TO', value: 'ship_to'},
      SHIPPING_SERVICE: {label: 'SHIPPING_SERVICE', value: 'shipping_service'},
      TRACKING: {label: 'TRACKING', value: 'tracking'},
      BARCODE: {label: 'BARCODE', value: 'barcode'},
      ITEM_DETAIL: {label: 'ITEM_DETAIL', value: 'item_detail'},
      COMPANY_POLICY: {label: 'COMPANY_POLICY', value: 'company_policy'},
      FOOTER: {label: 'FOOTER', value: 'footer'},
      STORE_LOGO: {label: 'STORE_LOGO', value: 'store_logo'},
      STORE_NAME: {label: 'STORE_NAME', value: 'store_name'},
      THIRD_PARTY_NAME: {
        label: 'UTILIZE_THE_EXISTING_3RD_PARTY_STORE_NAME',
        value: 'third_party_name',
      },
      STORE_LOCATION: {label: 'STORE_LOCATION', value: 'store_location'},
    },
  },
  STORE_LOCATION: {
    ADDRESS: {label: 'ADDRESS', value: 'address', required: true},
    ADDRESS_2: {label: 'ADDRESS_2', value: 'address_2', required: false},
    CITY: {label: 'CITY', value: 'city', required: true},
    STATE: {label: 'STATE', value: 'state', required: true},
    COUNTRY: {label: 'COUNTRY', value: 'country', required: true},
    ZIP: {label: 'POSTAL_CODE', value: 'zip', required: true},
  },
  VALIDATE_FILE: {
    SIZE: 8388608,
    EXTENSION_ALLOW: ['jpg', 'jpeg', 'png'],
  },

  // preset
  DEFAULT_PRESETS: {
    SHIPBAE: {label: 'ShipBae', value: 'shipbae'},
    SHOPIFY: {label: 'Shopify', value: 'shopify'},
    SHIPSTATION: {label: 'ShipStation', value: 'shipstation'},
    WALMART: {label: 'Walmart', value: 'walmart'},
    TIKTOK: {label: 'TikTok', value: 'tiktok'},
    EBAY: {label: 'eBay', value: 'ebay'},
    TEMU: {label: 'Temu', value: 'temu'},
  },
  SHOW_PRESET: {
    PRESET_NAME: {name: 'PRESET_NAME', show: true},
    SHIP_FROM: {name: 'SHIP_FROM', show: false},
    SHIP_TO: {name: 'SHIP_TO', show: false},
    SHIPPING_SERVICE: {name: 'SHIPPING_SERVICE', show: false},
    PACKAGE_TYPE: {name: 'PACKAGE_TYPE', show: false},
    SIZE: {name: 'SIZE', show: false},
    WEIGHT: {name: 'WEIGHT', show: false},
  },
}

export const MonthlyShipmentVolume = [
  {label: 'I_SHIP_OCCASIONALLY_UNDER_10_PACKAGES_PER_MONTH', value: 'under_10'},
  {label: '11_100_PACKAGES_PER_MONTH', value: '11_100'},
  {label: '101_500_PACKAGES_PER_MONTH', value: '101_500'},
  {label: '501_1000_PACKAGES_PER_MONTH', value: '501_1000'},
  {label: '1001_5000_PACKAGES_PER_MONTH', value: '1001_5000'},
  {label: 'MORE_THAN_5000_PACKAGES_PER_MONTH', value: 'more_5000'},
]

export const ConfigGeneral = {
  MAX_EMAIL_NUMBER: 3,
  NAME_TAB: {
    NOTIFICATIONS: 'notifications',
    LANGUAGE: 'language',
    TIME_ZONE: 'time-zone',
  },
  NOTIFICATION_CATEGORY: {
    METER: 'meter',
    CLAIMS: 'claims',
  },
  NOTIFICATION_TYPE: {
    EMAIL: 'email',
  },
}

// profile
export const OPTIONS_SERVICES = [
  {
    label: 'USPS',
    value: 'usps',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
]

export const OPTIONS_METHODS = [
  {
    label: 'ShipBae',
    value: 'shipbae',
  },
  {
    label: 'Gori API',
    value: 'gori_api',
  },
]

export const PROFILE_EDIT_FIELD = {
  ABOUT_YOUR: [
    {
      name: 'company_name',
      label: 'COMPANY_NAME',
      type: 'text',
      required: true,
    },
    {
      name: 'phone',
      label: 'PHONE',
      type: 'text',
      required: true,
    },
    {
      name: 'monthly_shipment_volume',
      label: 'SHIPMENT_VOLUME',
      type: 'select',
      required: true,
      options: MonthlyShipmentVolume,
      placeholder: 'HOW_CAN_HELP',
    },
    {
      name: 'sales_rep',
      label: 'SALE_REP',
      type: 'text',
      required: false,
      placeholder: 'SALE_REP',
    },
  ],
  BUSINESS_ADDRESS: [
    {
      name: 'country',
      label: 'COUNTRY',
      type: 'select',
      required: true,
      options: OPTION_COUNTRIES,
    },
    {
      name: 'street1',
      label: 'ADDRESS',
      type: 'address',
      required: true,
      col: 'col-12',
    },
    {
      name: 'street2',
      label: 'ADDRESS_2',
      type: 'address',
      required: false,
      col: 'col-6',
    },
    {
      name: 'city',
      label: 'CITY',
      type: 'text',
      required: true,
      col: 'col-6',
    },
    {
      name: 'zip',
      label: 'POSTAL_CODE',
      type: 'text',
      required: true,
      col: 'col-6',
    },
    {
      name: 'state',
      label: 'STATE',
      type: 'text',
      required: true,
      col: 'col-6',
    },
  ],
  PRIMARY_CONTACT: [
    {
      name: 'report_name',
      label: 'NAME',
      type: 'text',
      required: true,
      col: 'col-12',
    },
    {
      name: 'report_email',
      nameParent: 'report_emails',
      label: 'EMAILS',
      type: 'email',
      required: true,
      col: 'col-12',
    },
    {
      name: 'accounting_name',
      label: 'NAME',
      type: 'text',
      required: true,
      col: 'col-12',
    },
    {
      name: 'accounting_email',
      nameParent: 'accounting_emails',
      label: 'EMAILS',
      type: 'email',
      required: true,
      col: 'col-12',
    },
  ],
}
