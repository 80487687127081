import {cloneDeep, isEmpty, lowerCase, size, startCase, upperCase} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {ShowAddress} from '../../../../../../_gori/components'
import {TableTabsSetting} from '../../../../../../_gori/partials/widgets/tables/TableTabsSetting'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, TABLE_KEY_COLUMN} from '../../../../../../_gori/constants'
import {
  formatOmitLongText,
  setLoadingActionTable,
  toAbsoluteUrl,
  useDisabled,
} from '../../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableWrapper,
} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {EditPresetModal, PresetFilter} from '../../../../settings'
import SettingsService from '../../../core/_requests'
import {SettingsConfig} from '../../../core/_const'
import clsx from 'clsx'

const PresetPage: React.FC = () => {
  const intl = useIntl()
  const {loadingSwitch} = useAuth()
  const [statistics] = useState([])
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [dataPresetDefault, setDataPresetDefault] = useState<any>([])
  const [integrationStores, setIntegrationStores] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [showModal, setShowModal] = useState<{
    addPreset: boolean
    editPreset: boolean
    confirmDelete: boolean
  }>({
    addPreset: false,
    editPreset: false,
    confirmDelete: false,
  })
  const [dataEditPreset, setDataEditPreset] = useState(undefined)
  const [idDelete, setIdDelete] = useState<number | undefined>(undefined)
  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
      },
      cancelToken: newCancelToken(),
    }

    await SettingsService.getShippingPresets(config)
      .then((response: any) => {
        let newOrder = Array.from(response.shipping_presets, (shipping_preset: any) => {
          return {
            ...shipping_preset,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setPagination(response.pagination)
      })
      .catch((error) => {
        if (isCancel(error)) return
      })
      .finally(() => {
        setLoadingFirst(false)
        setPageDisabled(false)
      })
  }, [isCancel, newCancelToken, searchParams, setPageDisabled])

  const getDefaultPresetData = useCallback(async () => {
    try {
      const res = await SettingsService.getDefaultPreset({cancelToken: newCancelToken()})
      setDataPresetDefault(res.default_preset)
    } catch (error) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken])

  const getIntegrationStores = useCallback(async () => {
    try {
      const response = await SettingsService.connections({cancelToken: newCancelToken()})
      setIntegrationStores(response.connections)
    } catch (error) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken, setPageDisabled])

  const reloadTable = useCallback(() => {
    getDefaultPresetData()
    setTable()
  }, [getDefaultPresetData, setTable])

  useEffect(() => {
    if (loadingSwitch) return
    setTable()
    getIntegrationStores()
    getDefaultPresetData()

    return () => {}
  }, [searchParams, loadingSwitch, setTable, getIntegrationStores, getDefaultPresetData])

  const handleDeleteItem = useCallback(async () => {
    if (!idDelete) return
    setTableData(setLoadingActionTable(tableData, [idDelete], true))

    const payload = {
      preset_ids: [idDelete],
    }

    try {
      const {message} = await SettingsService.deleteShippingPreset(payload, {
        cancelToken: newCancelToken(),
      })
      if (message) {
        reloadTable()
        setIdDelete(undefined)
        toast.success(intl.formatMessage({id: message}))
      }
    } catch (error) {
      if (isCancel(error)) return
      toast.error(intl.formatMessage({id: 'DELETED_FAILED'}))
    } finally {
      setTableData(setLoadingActionTable(tableData, [idDelete], false))
    }
  }, [idDelete, intl, isCancel, newCancelToken, reloadTable, tableData])

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      },
      {
        Header: intl.formatMessage({id: 'NAME'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='d-flex align-items-center'>
              <div>{row.original?.name}</div>
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIP_FROM'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => {
          const dataFrom = row.original?.shipping_options?.from
          return <ShowAddress data={dataFrom} name='from' />
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => {
          const dataTo = row.original?.shipping_options?.to
          return <ShowAddress data={dataTo} name='to' />
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIPPING_SERVICE'}),
        headerClassName: 'min-w-175px',
        Cell: ({row}: {row: any}) => {
          const firstText = row.original?.shipping_options?.service?.split('_')[0]?.toUpperCase()
          const afterText = startCase(
            row.original?.shipping_options?.service?.split('_')?.splice(1).join(' ')
          )
          const showTextService = firstText && afterText ? `${firstText} ${afterText}` : ''

          return <div>{showTextService}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'WEIGHT'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          const parcels = row.original?.shipping_options?.parcels?.[0]
          const unit = parcels?.weight_unit
          return parcels?.weight ? (
            <div>
              {parcels?.weight}
              {unit ? ` ${parcels?.weight_unit}` : ''}
            </div>
          ) : unit ? (
            unit
          ) : null
        },
      },
      {
        Header: intl.formatMessage({id: 'SIZE'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          const parcels = row.original?.shipping_options?.parcels?.[0]
          const length = parcels?.length
          const width = parcels?.width
          const height = parcels?.height
          const unit = parcels?.dimension_unit

          return length || width || height ? (
            <div>
              {length && Number.parseFloat(length)}
              {' x '}
              {width && Number.parseFloat(width)}
              {' x '}
              {height && Number.parseFloat(height)}
              {unit ? ` ${parcels?.dimension_unit}` : ''}
            </div>
          ) : unit ? (
            unit
          ) : null
        },
      },
      {
        Header: intl.formatMessage({id: 'DEFAULT'}),
        headerClassName: 'min-w-250px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          const arrDefault = cloneDeep(dataPresetDefault).filter(
            (preset) => preset.preset_id === row.original.id
          )
          if (isEmpty(arrDefault)) {
            return null
          }

          const stores = cloneDeep(integrationStores)
          const listStoreNameShown: any = []
          const analyticsPresetData: any = []
          Object.entries(SettingsConfig.DEFAULT_PRESETS).map(([key, item]: [any, any]) => {
            analyticsPresetData.push({
              store_type: item.value,
              total_store: stores[item.value]?.length ?? 0,
              total_applied: row.original.default_presets.filter((p) => p.order_type === item.value)
                .length,
            })
          })

          analyticsPresetData.map((item, key) => {
            if (item.total_store && item.total_store === item.total_applied) {
              listStoreNameShown.push(item)
            }
          })
          const listStoreTypeShown = listStoreNameShown.map((item) => item.store_type)
          const sortedStores = [...arrDefault].sort((storeA, storeB) =>
            storeA.store_name.localeCompare(storeB.store_name)
          )
          sortedStores.map((item) => {
            if (!listStoreTypeShown.includes(item.order_type)) {
              listStoreNameShown.push(item)
            }
          })

          return (
            <>
              {(row.original?.expand
                ? listStoreNameShown
                : [
                    listStoreNameShown[0],
                    listStoreNameShown[1],
                    listStoreNameShown[2],
                    listStoreNameShown[3],
                    listStoreNameShown[4],
                  ]
              ).map((item, key) => {
                if (item && item.total_store && item.total_applied) {
                  return (
                    <div className='d-flex align-items-center my-1'>
                      <img
                        alt={row.original.store_type}
                        src={toAbsoluteUrl(`/media/gori/settings/${item.store_type}.png`)}
                        className={'h-15px me-2'}
                      />
                      <div>
                        {intl.formatMessage({id: 'ALL'})}
                        {' ' + intl.formatMessage({id: item.store_type.toUpperCase()}) + ' '}
                        {intl.formatMessage({id: 'STORES'})}
                      </div>
                    </div>
                  )
                }
                if (item && !item.total_store && !item.total_applied) {
                  return (
                    <div className='d-flex align-items-center my-1'>
                      <img
                        alt={item.order_type}
                        src={toAbsoluteUrl(`/media/gori/settings/${item.order_type}.png`)}
                        className={'h-15px me-2'}
                      />
                      <div>{item.store_name}</div>
                    </div>
                  )
                }
              })}

              <div
                className={clsx(
                  'text-primary text-center text-hover-active-primary fw-bold cursor-pointer text-decoration-underline',
                  {
                    'd-none': listStoreNameShown.length <= 5,
                    'min-w-150px': listStoreNameShown.length > 5,
                  }
                )}
                onClick={() => {
                  setTableData(
                    setLoadingActionTable(
                      tableData,
                      [row.original.id],
                      !row?.original?.expand,
                      'expand'
                    )
                  )
                }}
              >
                {intl.formatMessage({id: row.original?.expand ? 'COLLAPSE' : 'VIEW_MORE_ITEMS'})}
              </div>
            </>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => (
          <DropdownButton
            loading={row.original.isLoading}
            list={[
              {
                label: intl.formatMessage({id: 'EDIT'}),
                action: () => {
                  setDataEditPreset(row.original)
                  setShowModal((prev) => ({...prev, editPreset: true}))
                },
                className: 'cursor-pointer',
              },
              {
                label: intl.formatMessage({id: 'DELETE'}),
                action: () => {
                  setIdDelete(row.original.id)
                  setShowModal((prev) => ({...prev, confirmDelete: true}))
                },
                className: 'cursor-pointer',
              },
            ]}
          />
        ),
      },
    ],
    [dataPresetDefault, intl]
  )

  return (
    <>
      {showModal.confirmDelete && (
        <ConfirmActionSwal
          show={showModal.confirmDelete}
          title={intl.formatMessage({id: 'DELETE_PRESET'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDeleteItem}
          handleClose={() => setShowModal((prev) => ({...prev, confirmDelete: false}))}
        />
      )}

      {showModal.editPreset && (
        <EditPresetModal
          show={showModal.editPreset}
          handleClose={() => {
            setShowModal((prev) => ({...prev, editPreset: false}))
            setDataEditPreset(undefined)
          }}
          dataEditPreset={dataEditPreset}
          reloadTable={reloadTable}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <TableWrapper>
              <TableBody>
                <div className='mt-6 d-flex  align-item-center justify-content-between'>
                  <div className='col-3'>
                    <span className='fs-1 col-12 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  </div>
                  <div className='col-2 d-flex flex-row-reverse'>
                    <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <hr className='mb-8' />
                <TableSkeleton countRow={8} />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <>
            <TableWrapper>
              <div className='mb-4'>
                <TableTabsSetting
                  dataTabs={statistics}
                  keyCheckActive='status'
                  title={intl.formatMessage({id: 'PRESETS'})}
                />
              </div>
              <TableBody>
                <PresetFilter
                  selectedListId={selectedListId}
                  reloadTable={reloadTable}
                  dataPresetDefault={dataPresetDefault}
                />
                <Table
                  columns={columns}
                  data={tableData}
                  pagination={pagination}
                  tbodyClass='text-gray-600 fw-bold'
                  usePagination
                  useCheckBox
                  callBackSetData={(selected) => {
                    setSelectedListId(selected)
                  }}
                />
              </TableBody>
            </TableWrapper>
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {PresetPage}
