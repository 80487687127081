import {useCallback, useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import SettingsService from '../../modules/settings/core/_requests'
import {toast} from 'react-toastify'
import {StorageHelpers} from '../../../_gori/helpers'
import {useIntl} from 'react-intl'
import {OrderType} from '../../modules/orders/core/_const'
import {useAuth} from '../../modules/auth'

export function GlobalShopifyVerifyPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const store = searchParams.get('shop')
  const accessToken = searchParams.get('access_token')
  const scope = searchParams.get('scope')
  const {currentUser} = useAuth()

  const connectShopify = useCallback(async () => {
    try {
      const res = await SettingsService.connect({
        store: store,
        access_token: accessToken,
        scope: scope,
        store_type: OrderType.SHOPIFY,
      })
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTION_SUCCESSFUL'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
    } finally {
      StorageHelpers.setItemLocalStorage('IS_LOADING', false)
      if (currentUser?.onboard_step === 'done') {
        navigate('/settings/connections/shopify')
      } else {
        StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
        navigate('/your-info')
      }
    }
  }, [accessToken, intl, navigate, scope, store, currentUser])

  useEffect(() => {
    connectShopify()
  }, [connectShopify])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
