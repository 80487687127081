import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {StorageHelpers} from '../../../../../../../_gori/helpers'
import {LoadingPage} from '../../../../../../../_gori/helpers/components/LoadingPage'
import {useAuth} from '../../../../../auth'
import {OrderType} from '../../../../../orders/core/_const'
import SettingsService from '../../../../core/_requests'
import {isEmpty} from 'lodash'

const VerifyTikTok: React.FC = () => {
  const intl = useIntl()
  const [queryParams] = useSearchParams()
  const app_key = queryParams.get('app_key')
  const code = queryParams.get('code')
  const locale = queryParams.get('locale')
  const shop_region = queryParams.get('shop_region')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {currentUser} = useAuth()

  const connectTikTok = useCallback(async () => {
    setIsLoading(true)
    await SettingsService.connect({
      app_key: app_key,
      code: code,
      locale: locale,
      shop_region: shop_region,
      store_type: OrderType.TIKTOK,
    })
      .then(async () => {
        toast.success(intl.formatMessage({id: 'CONNECTION_SUCCESSFUL'}))
      })
      .catch((error) => {
        let message = error?.response?.data?.error?.message ?? ''
        toast.error(isEmpty(message) ? intl.formatMessage({id: 'CONNECTIONS_FAILED'}) : message)
      })
      .finally(() => {
        setIsLoading(false)
        if (currentUser?.onboard_step === 'done') {
          navigate('/settings/connections/tiktok')
        } else {
          StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
          navigate('/your-info')
        }
      })
  }, [app_key, code, locale, shop_region, intl, currentUser?.onboard_step, navigate])

  useEffect(() => {
    connectTikTok()
  }, [connectTikTok])

  return isLoading ? <LoadingPage className='start-0' /> : <></>
}

export {VerifyTikTok}
