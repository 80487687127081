import clsx from 'clsx'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {TABLE_KEY_COLUMN} from '../../../../../../_gori/constants'
import {
  convertUserTimeZone,
  KTSVG,
  setLoadingActionTable,
  useDisabled,
} from '../../../../../../_gori/helpers'
import {getClickableLink} from '../../../../../../_gori/helpers/BrowserHelpers'
import {TableSkeleton} from '../../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, Table, TableBody, TableWrapper} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {OrderType} from '../../../../orders/core/_const'
import SettingsService from '../../../core/_requests'
import {RemoveStoreModal} from '../../../index'

type Props = {
  storeType: string
  isReload?: boolean
  setIsReload?: React.Dispatch<React.SetStateAction<boolean>>
}

const StoresListing: React.FC<Props> = ({storeType, isReload, setIsReload}) => {
  const intl = useIntl()
  const {loadingSwitch, currentUser} = useAuth()
  const {pageDisabled, setPageDisabled} = useDisabled()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [connections, setConnections] = useState<any>([])
  const [showModal, setShowModal] = useState<{removeStore: boolean}>({removeStore: false})
  const [dataModal, setDataModal] = useState<{removeStore: {data: any}}>({
    removeStore: {data: null},
  })

  const loadConnections = useCallback(async () => {
    setPageDisabled(true)
    try {
      const response = await SettingsService.connections({
        params: {
          store_type: storeType,
          status: 'all',
        },
        cancelToken: newCancelToken(),
      })
      setConnections(response.connections)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoadingFirst(false)
      setPageDisabled(false)
      if (setIsReload) {
        setIsReload(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken, setIsReload, setPageDisabled])

  const disconnectStore = useCallback(
    async ({id}) => {
      setConnections(setLoadingActionTable(connections, [id], true))

      try {
        const {message} = await SettingsService.disconnect({
          params: {
            store_type: storeType,
            store_id: id,
          },
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          loadConnections()
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setConnections(setLoadingActionTable(connections, [id], false))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connections, intl, isCancel, loadConnections, newCancelToken]
  )

  const reconnectStore = useCallback(
    async ({id, store_url}) => {
      setPageDisabled(true)

      if (storeType === OrderType.SHOPIFY || storeType === OrderType.EBAY) {
        try {
          const getLink =
            storeType === OrderType.SHOPIFY
              ? await SettingsService.getShopifyIntegrateLink(store_url)
              : await SettingsService.getEbayConsentLink({cancelToken: newCancelToken()})
          window.location.replace(getLink)
        } catch (error) {
          toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
        } finally {
          setPageDisabled(false)
        }
      } else {
        const {message} = await SettingsService.connect({
          store_type: storeType,
          store_id: id,
        })
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          loadConnections()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connections, intl, isCancel, loadConnections, newCancelToken, setPageDisabled]
  )

  const columns = useMemo(() => {
    let storeUid: any = {
      id: 'store_uid',
      Header: intl.formatMessage({id: 'STORE_UID'}),
      headerClassName: 'min-w-100px text-start',
      cellClassName: 'text-dark text-start',
      Cell: ({row}: {row: any}) => {
        return row.original.store_id
      },
    }
    let storeName: any = {
      id: 'store_name',
      Header: intl.formatMessage({id: 'STORE_NAME'}),
      headerClassName: 'min-w-100px',
      cellClassName: 'text-dark',
      Cell: ({row}: {row: any}) => {
        switch (storeType) {
          case OrderType.SHOPIFY:
            return (
              <a
                target='_blank'
                href={getClickableLink(row.original.store_url)}
                rel='noreferrer'
                className='text-truncate'
              >
                {row.original.store_name}
              </a>
            )
          default:
            return <>{row.original.store_name}</>
        }
      },
    }
    let expireAt: any = {
      id: 'expire_at',
      Header: intl.formatMessage({id: 'EXPIRE_AT'}),
      headerClassName: 'min-w-100px text-center',
      cellClassName: 'text-dark text-center',
      Cell: ({row}: {row: any}) => {
        let expiresAt = ''
        let message = ''
        switch (storeType) {
          case OrderType.TIKTOK:
            expiresAt = row?.original?.expires_at
              ? convertUserTimeZone(row?.original?.expires_at, currentUser, 'YYYY-MM-DD HH:mm')
              : ''
            message = intl.formatMessage({id: 'AUTHORIZATION_NOTIFICATION_TIKTOK'})
            break
          case OrderType.EBAY:
            expiresAt = row?.original?.refresh_token_expires_in ?? ''
            message = intl.formatMessage(
              {id: 'AUTHORIZATION_NOTIFICATION'},
              {
                type: intl.formatMessage({id: 'EBAY'}),
                year: 1,
              }
            )
            break
          case OrderType.TEMU:
            expiresAt = row?.original?.expires_in ?? ''
            message = intl.formatMessage(
              {id: 'AUTHORIZATION_NOTIFICATION'},
              {
                type: intl.formatMessage({id: 'TEMU'}),
                year: 1,
              }
            )
            break
        }
        return (
          <>
            {expiresAt && (
              <div
                className={clsx('p-3', {
                  'text-danger': new Date(expiresAt).getTime() < Date.now(),
                })}
              >
                {expiresAt}
                {expiresAt && (
                  <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>{message}</Tooltip>}>
                    <span>
                      <KTSVG
                        path='/media/gori/orders/exclamation.svg'
                        className='ms-2 mb-1 svg-icon-4 cursor-pointer'
                      />
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            )}
          </>
        )
      },
    }
    let actions: any = {
      id: TABLE_KEY_COLUMN.ACTIONS,
      Header: intl.formatMessage({id: 'ACTIONS'}),
      headerClassName: 'min-w-100px text-end pe-6 fixed-column',
      cellClassName: 'text-dark text-end fixed-column',
      Cell: ({row}: {row: any}) => {
        return (
          <div
            className={clsx('btn-group', {
              'cursor-no-drop': row.original.isLoading,
            })}
          >
            {row.original.status === 'active' ? (
              <>
                <Button
                  className={clsx('btn btn-light-danger btn-sm rounded-1', {
                    'pe-none': row.original.isLoading,
                    'cursor-no-drop': row.original.isLoading,
                  })}
                  event={() => disconnectStore(row.original)}
                  loading={row.original.isLoading}
                  loadingText={intl.formatMessage({id: 'DISCONNECT'})}
                  label={intl.formatMessage({id: 'DISCONNECT'})}
                />
                {row.original?.outdated && (
                  <Button
                    className={clsx('ms-2 btn btn-danger btn-sm', {
                      'pe-none cursor-no-drop': pageDisabled,
                    })}
                    event={() => reconnectStore(row.original)}
                    disabled={pageDisabled}
                    loadingText={intl.formatMessage({id: 'UPDATE_STORE'})}
                    label={intl.formatMessage({id: 'UPDATE_STORE'})}
                  />
                )}
              </>
            ) : (
              <Button
                className={clsx('btn btn-primary btn-sm rounded-1', {
                  'pe-none cursor-no-drop': pageDisabled,
                })}
                event={() => reconnectStore(row.original)}
                disabled={pageDisabled}
                loadingText={intl.formatMessage({id: 'RECONNECT'})}
                label={intl.formatMessage({id: 'RECONNECT'})}
              />
            )}
            <div className='d-flex justify-content-center align-items-center ms-4 text-gray-600 text-hover-danger cursor-pointer'>
              <span
                onClick={() => {
                  setDataModal((prev) => ({...prev, removeStore: {data: row.original}}))
                  setShowModal((prev) => ({...prev, removeStore: true}))
                }}
              >
                X
              </span>
            </div>
          </div>
        )
      },
    }

    return [
      storeUid,
      storeName,
      ...(storeType === OrderType.TIKTOK ||
      storeType === OrderType.EBAY ||
      storeType === OrderType.TEMU
        ? [expireAt]
        : []),
      actions,
    ]
  }, [intl, storeType, currentUser, pageDisabled, disconnectStore, reconnectStore])

  useEffect(() => {
    if (loadingSwitch) return

    loadConnections()
  }, [loadConnections, loadingSwitch, isReload])

  return (
    <>
      {showModal.removeStore && dataModal.removeStore.data && (
        <RemoveStoreModal
          show={showModal.removeStore}
          handleClose={() => {
            setShowModal((prev) => ({...prev, removeStore: true}))
            setDataModal((prev) => ({...prev, removeStore: {data: null}}))
          }}
          data={dataModal.removeStore.data}
          reloadTable={() => loadConnections()}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableWrapper className='rounded-top-0 px-20'>
          <TableBody>
            {loadingFirst ? (
              <TableSkeleton countColumn={3} />
            ) : (
              <Table
                columns={columns}
                data={connections}
                tbodyClass='text-gray-600 fw-bold'
                usePagination
              />
            )}
          </TableBody>
        </TableWrapper>
      </CSSTransition>
    </>
  )
}

export {StoresListing}
